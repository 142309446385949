.role-persona-section{
  .role-persona-container{
    background-color: #FFFFFF;
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 20px;
    margin-top: 10px;
    border: 1px solid gainsboro;
    width: calc(100vw - 245px);
    table {
        padding: 0px 10px !important;
      }
  }
}
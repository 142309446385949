.pop-up-message-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 30px;

    h4 {
        font-weight: 600;
        font-size: 18px;
        padding-bottom: 0px;
        text-align: center;
        padding: 0px 100px;
    }

    &.empty-message{
        margin-bottom: unset;
    }
}

.pop-up-btn-block{
    display: flex;
    justify-content: center;
    button {
        margin: 10px;
    }
}
//Variables
$primary-white: #FFFFFF;
$primary-black: #2f323c;
$primary-red: #FA1565;
$secondary-red: #e3126c;
$primary-purple: #7314ad;
$primary-purple-gradient: linear-gradient(111deg, #fa1565 22%, #7314ad 155%);
$primary-blue-gradient: linear-gradient(111deg, #6465ca 22%, #424a79 155%);
$dark-grey: #6F747A;
$dark-ash: #C4CAD2;
$ash-white: #F1F3F5;
$dark-blue: #162C4C;
$aqua-blue: #01D4DF;
$yellow: #FFDD00;
$green: #76B043;
$secondary-purple: #7958ED;
$blue: #1673F4;
$blue-ash: #797da5;
$body-ash: #f0f3f8;
$title-blue: #333860;
$text-blue: #495281;
$bar-ash: #d3d6ed;
$bar-red: #dc1576;
$number-blue: #626b91;
$label-blue: #424a79;
$ash-red: #b0b4d0;
$ash-border: #a5b8d8;
$light-meron: #fce5eb;
$c4-border: #c4c4c4;
$body-blue: #393e65;
$icon-purple: #f71467;
$light-green: #e5fbe7;
$dark-blue-header: #363c69;
$aqua-green: #47cae2;
$aqua-ash: #f7f9fc;
$blue-bg0: #ffffff;
$blue-bg1: #FBE2E6;
$blue-bg2: #F1C3CB;
$blue-bg3: #EFA9BC;
$blue-bg4: #D590B1;
$blue-bg5: #B78BB3;
$blue-bg6: #987CB0;
$blue-bg7: #7F75B2;
$error-red: #f44336;
$popeper-blue: #3A3F62;
$semi-transparent-dark-gray: rgba(0,0,0,0.2);
$semi-transparent-lighter-black: rgba(0, 0, 0, 0.12);
$charcoal-gray: #8C8C8C;
$ight-gray: #D8D8D8;
$error: #DA3832;
$field-gradient: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.02) 100%);
$downloaded: #3AB876;
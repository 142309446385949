.Side-menu {
  background: rgb(250, 21, 101);
  background: linear-gradient(
    180deg,
    rgba(250, 21, 101, 1) 0%,
    rgba(115, 20, 173, 1) 100%
  );
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: transparent;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #7314ad;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: transparent;
    border-radius: 3px;
  }
  &.active {
    z-index: 1301;
  }
  &-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: inherit;
    width: 60px;
  }
  ul {
    &:first-child {
      li {
        &:first-child {
          margin-bottom: 10px;
          pointer-events: none;
          position: relative;
          &:before {
            content: "";
            background-color: #ffffff;
            height: 1px;
            bottom: 0px;
            position: absolute;
            left: 50%;
            margin-left: -20px;
            width: 40px;
          }
        }
      }
    }
    li {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      padding: 5px 0px;
      span {
        display: flex;
        align-items: center;
        height: 45px;
        padding: 5px 18px;
        position: relative;
        text-align: center;
        width: 60px;
        img,
        svg {
          height: 26px;
          width: 26px;
        }
        svg {
          path {
            fill: #ffffff;
          }
        }
        &:before {
          content: "";
          background-color: #ffffff;
          height: 0px;
          width: 5px;
          position: absolute;
          left: 0px;
          top: 100%;
          margin-top: -24px;
          transition: all 0.2s linear;
        }
        &:hover {
          &:before {
            height: 36px;
            top: 50%;
          }
        }
      }
    }
    &:last-child {
      li {
        padding: 0px;
        span {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}

@import '../../../../style/Colors.scss';

.detail-block {
    display: flex;
    font-size: 12px;

    .border-btm {
        border-bottom: solid 1px $ash-border;
        margin-bottom: 30px;
    }

    .line-text {
        line-height: 16px;
    }

    h4,
    h5 {
        text-transform: uppercase;
    }
}
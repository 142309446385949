@import "../../../style/CommonStyles.scss";

.progress-bar-container{
    width: 250px;
    height: 52px;
    position: fixed;
    top: 12%;
    right: 35px;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 6px 20px 11px 16px;
    border: 1px solid #424a79;
    z-index: 999;
    cursor: context-menu;
    .progress-text{
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .progress-percentage{
        font-size: 14px;
        font-weight: 700;
    }
    .show-cross-icon{
        position: absolute;
        right: 18px;
        top: 15px;
        cursor: pointer;
        svg{
            width: 0.8em;
            height: 0.8em;
        }
        .clear-icon{
            cursor: pointer;
        }
    }
    .progress-info{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: "100%",
    }
    .progress-bar{
        width: 100%;
        margin-top: 4px;
        .MuiLinearProgress-colorPrimary{
            background: #D9D9D9;
        }
        .MuiLinearProgress-barColorPrimary{
            background: linear-gradient(90deg, #F91465 0%, #7F2F5C 100%, #B1188E 100.1%);
        }
    }
    .download-data{
        position: absolute;
        top: 24px;
        left: 44px;
        cursor: pointer;
        >div{
            display: flex;
            align-items: center;
            gap: 5px;
            background: linear-gradient(90deg, #F91465 -3.57%, #B1188E 100%);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
        }
    }
}
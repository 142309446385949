@import "../../../../style/Colors.scss";
@import "../../../../style/CommonStyles.scss";

.jd-modal-container {
    @include flexCenter;
    flex-direction: column;
    gap: 20px;
    margin: 30px 70px 10px 30px;
    padding: 0px 100px;
    padding-bottom: 40px;
    text-align: center;
    border-bottom: 1px solid $ight-gray;


    .download-message {
        display: flex;
        flex-direction: column;
        gap: 5px;
        :not(:last-child) {
            font-size: 14px;
            color: $charcoal-gray;
        }

        >:first-child {
            font-size: 16px;
            font-weight: 700;
        }

        >:last-child {
            padding-top: 5px;
            font-size: 16px;
            font-weight: 700;
        }
    }

    .download-upload-container{
        margin-top: 10px;
        @include flexCenter;
        gap: 30px;
        .downloaded{
            span{
                display: flex; 
                justify-content: center; 
                align-items: center;
                gap: 5px;
            }
        }
    }

    .failed-response-container{
        margin-top: 10px;
        .note{
            font-weight: 800;
            color: $error;
            font-size: 14px;
        }
        .note-info{
            color: $error;
        }
        .click-here{
            position: relative;
            margin-right: 22px;
            color: #3f9bc5;
            cursor: pointer;
            img{
                position: absolute;
                top: 2px;
            }
        }
    }
   
}
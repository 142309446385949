@import "../../../style/Colors.scss";
@import "../../../style/CommonStyles.scss";


.file-uploader-container {
    margin: 30px 60px 30px;
    max-height: 220px;
    display: flex;
    justify-content: center;

    .file-input-container {
        width: 450px;

        .input {
            display: none;
        }

        .MuiBox-root {
            padding: 45px 20px;
        }

        .file-uploader-text {
            padding: 0px
        }

        .support-file-types, .support-file-size {
            padding-bottom: 0px;
        }

        .selected-files {
            padding-bottom: 20px;

            >div {
                background: $field-gradient;
            }

            .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
            .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline,
            .MuiOutlinedInput-root.Mui-focused:active .MuiOutlinedInput-notchedOutline {
                border: solid 1px $primary-black !important;
            }

            .MuiFormControl-fullWidth {
                margin-top: 8px;
            }

            .MuiOutlinedInput-input {
                padding: 12px 14px 12px 5px;
                border-radius: 8px;
                font-size: 14px;
                font-weight: 600;
            }

            .delete-icon-btn {
                border-radius: 6px;
                border: 1px solid $semi-transparent-lighter-black;
                padding: 4px 0px 2px 4px
            }
        }

        .invalid-template-error-message {
            padding: 10px 0px;

            img {
                padding-top: 4px;
            }

            display: flex;
            align-items: flex-start;
            gap: 10px;
            color: $error;

            .error {
                font-weight: 800;
            }
        }
    }

    .file-uploader-confirmation-btns {
        padding: 25px 0px;
    }
}
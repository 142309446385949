@import "../../../../style/Colors.scss";
@import "../../../../style/CommonStyles.scss";

.create-persona-container {
    @include flexCenter;
    gap: 30px;
    height: 100%;
    margin: 20px 0px;

    .create-persona-option {
        width: 40%;
        height: 100%;
        border: 1px solid $semi-transparent-lighter-black;
        border-radius: 8px;
        @include flexCenter;
        flex-direction: column;
        gap: 30px;
        padding: 50px 30px;
        text-align: center;
        cursor: pointer;

        &:hover {
            background:
                linear-gradient($primary-white, $primary-white) padding-box,
                linear-gradient(180deg, rgba(249, 20, 101, 1) 0%, rgba(177, 24, 142, 1) 50%) border-box;
        }
         
        &.disable-card{
            cursor: context-menu;
            opacity: 0.65;
            background: unset;
        }
    }
}
@import "../../../../style/Colors.scss";
@import "../../../../style/CommonStyles.scss";

.create-bulk-persona-container {
    padding: 10px 20px;

    .persona-cards-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
        padding: 10px 0px;

        .bulk-persona-container-card {
            position: relative;
            border: 1px solid $semi-transparent-lighter-black;
            border-radius: 8px;
            display: flex;
            align-items: center;
            padding: 20px 115px 20px 20px;
            gap: 30px;
            cursor: pointer;

            h4 {
                padding: 0px;
            }

            &:hover {
                background:
                    linear-gradient($primary-white, $primary-white) padding-box,
                    linear-gradient(180deg, rgba(249, 20, 101, 1) 0%, rgba(177, 24, 142, 1) 50%) border-box;
            }

            &.hrms-card {
                background-color: #F3F2F3;
                cursor: context-menu;
                pointer-events: none;

                h4 {
                    color: #8C8C8C;
                }
            }

            .hrms-card-btn {
                position: absolute;
                top: 8px;
                right: 8px;
                font-size: 12px;
                padding: 5px 10px;
                font-family: inherit;
                background-color: #3AB876;
                color: $primary-white;
                border-radius: 12px;
                border: unset;
            }

            &:first-child {
                padding-right: 70px;
            }
        }
    }

    .file-uploader-container {
        margin: 30px 0px 90px;
        border-top: 1px solid #E0E0E0;

        .file-input-container {
            margin-top: 40px;
        }
    }

    .skills-uploader-container{
        margin: 30px 0px;
        border-top: 1px solid #E0E0E0;
        .download-template-container{
            margin: 30px 0px;
            gap: 5px;
            @include flexCenter;
            .info-icon{
                cursor: pointer;
            }
        }
        .file-uploader-container {
            margin: unset;
            border-top: unset;
            .file-input-container {
                margin-top: unset;
            }
        }
    }
}
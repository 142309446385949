#sequence {
  width: 300px;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -150px;
  margin-left: -150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > div {
    p {
      padding: 10px 5px;
      font-weight: 400;
    }
    &:first-child,
    &:first-of-type {
      p {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}
#sequence text,
#legend text {
  font-weight: 600;
  fill: #fff;
}

#chart {
  //   padding-top: 25px;
  & > div {
    position: relative;
    .chart-breadCrumb {
      background-color: transparent;
      border-radius: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 411px;
      position: absolute;
      width: 411px;
      left: 50%;
      padding: 10px 50px;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
      & > div {
        font-size: 14px;
        line-height: 1;
        padding: 10px 5px;
        &:first-of-type {
          font-weight: 700;
        }
      }
    }
  }
  .sunburst-viz {
    .tooltip {
      font-size: 12px;
      font-family: "Nunito Sans",sans-serif !important;
      font-weight: 400;
      padding: 5px;
      white-space: pre-wrap;
    }
    .main-arc {
      fill-opacity: 0.6;
      stroke-width: 0.3px;
    }
    .hide {
      .main-arc {
        fill-opacity: 0;
      }
    }
    .text-stroke,
    .text-contour {
      stroke-width: 0 !important;
    }
    text {
      font-family: "Nunito Sans",sans-serif !important;
      font-size: 11px;
    }
  }
}
#chart > div {
  position: relative;
  margin: 0 auto;
  height: 1200px;
  width: 1200px;
}
#main {
  margin-top: 25px;
}
// #chart path {
//   stroke: #fff;
// }

@import "../../../../style/Colors.scss";
@import "../../../../style/CommonStyles.scss";

.create-persona-in-progress-container {
    @include flexCenter;
    flex-direction: column;
    gap: 25px;
    margin: 55px 0px 50px 0px;

    .in-progress-message {
        text-align: center;

        >:first-child {
            font-size: 13px;
            color: $charcoal-gray;
        }

        >:last-child {
            font-size: 14px;
            font-weight: 700;
        }
    }
}
@import '../../../../../style/Colors';
 
 .remove-column {
    padding-top: 62px;
    li {
      border-radius: 4px;
      color: $title-blue;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      span {
        font-size: 12px;
        font-weight: 400;
      }
      svg{
        color: $error-red;
        font-size: 1.4rem;
      }
    }
  }
.search-accordian-block {
    .selected-skills-container {
        padding: 15px;
        margin-top: 10px;
        margin-bottom: 20px;
        border-radius: 6px;
        border: 1px solid gainsboro;
    }

    .search-skills-container {
        .search-input-block {
            display: flex;
            align-items: flex-start;
            margin: 10px 0px 30px;

            &>div:first-child .MuiFormControl-root {
                border-radius: 5px;
            }

            .search-block {
                position: relative;

                .MuiAutocomplete-inputRoot {
                    background-color: #ffffff;
                }

                .MuiOutlinedInput-input {
                    padding-right: 45px;
                }

                button {
                    border: none;
                    position: absolute;
                    right: 0;
                    bottom: 4px;
                    min-width: 40px;
                    padding: 0px;
                }
            }

            .filter-block {
                display: flex;
                padding-top: 5px;

                &>* {
                    margin-right: 15px;

                    &:first-child {
                        padding-top: 38px;
                        margin-left: 15px;
                    }
                }

                .source-drop-down {
                    margin-top: -5px;

                    label {
                        color: #2f323c;
                        font-size: 16px;
                        font-family: inherit;
                        font-weight: bold;
                        white-space: nowrap;
                        padding-bottom: 12px;
                    }

                    input {
                        color: #2f323c;
                    }

                    fieldset {
                        border: 1px solid #a5b8d8;
                        max-height: 41px
                    }
                }
            }
        }

        .search-input-bottom-block {
            display: flex;
            align-items: flex-start;

            &>div {
                &:last-child {
                    padding: 6px 0px 0px 15px;
                }
            }

            .MuiInputBase-root {
                margin-bottom: 0px;
            }
        }
    }
}
@import "../../../style/Colors.scss";
@import "../../../style/CommonStyles.scss";

.error-label {
    display: flex;
    align-items: center;
    color: $error;
    font-size: 14px;
    column-gap: 8px;
}

.error-title {
    font-weight: bold;
}


@import '../../../../style/Colors';

.button-select-all-container {
    .select-drop-down {
        .btn-text {
            border-right: 1px solid;
            padding-right: 8px;
        }
        .MuiButton-endIcon {
            margin-left: 5px;
        }
        button{
            min-width: max-content;
            box-shadow: none;
            &:hover{
                box-shadow: 0px 5px 5px -3px $semi-transparent-dark-gray, 0px 8px 10px 1px $semi-transparent-lighter-black, 0px 3px 14px 2px $semi-transparent-lighter-black;
            }
        }
    }
}

.menu-pop-hover {
    .help-icon {
        color: $dark-grey !important;
        width: 18px;
        height: 18px;
        margin-left: 5px;
        bottom: 3px;

        &.help-icon-disable {
            color: $semi-transparent-lighter-black;
        }
    }

    .clear-role-matching {
        .clear-icon {
            width: 18px;
            height: 18px;
            margin-left: 5px;
            color: $dark-grey !important;

            svg {
                &.MuiSvgIcon-root {
                    width: 0.8em;
                    height: 0.8em;
                }
            }
        }
    }

    li.MuiButtonBase-root {
        position: relative;
        margin: 6px;

        &:before {
            content: "";
            background-color: $primary-white;
            height: 1px;
            width: 78%;
            position: absolute;
            bottom: 0px;
            left: 11%;
        }

        &:last-child {
            &:before {
                display: none;
            }
        }
    }
}